import { Box } from '@mui/material';
import { PageWrapper } from 'components';

import { authentication } from '@microsoft/teams-js';

export const TeamsAuthPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  if (code) {
    authentication.notifySuccess(code);
  } else {
    authentication.notifyFailure(
      'Authorization code not found in redirect URI.',
    );
  }

  return (
    <PageWrapper>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      ></Box>
    </PageWrapper>
  );
};
